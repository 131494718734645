import { createRouter, createWebHashHistory } from "vue-router";
import IndustryTrainingRoutes from './utils/Router/IndustryTraining';
import webSiteRoutes from './utils/Router/webSite';
const importPage = (path) => import("@/pages/" + path);
const importLayout = (path) => import("@/layouts/" + path);

// 路由表
const routes = [
  {
    path: "/",
    redirect: "/index",
  },

  // ===== 系统首页 =====
  {
    path: "/index",
    component: () => importLayout("mainLayout"),
    children: [
      {
        path: "",
        components: { main: () => importPage("index") },
        meta: { title: "系统首页", primaryMenuId: "index" },
      },
    ],
  },

  // ===== 内容管理 =====
  {
    path: "/content",
    component: () => importLayout("contentLayout"),
    children: [
      {
        path: "index",
        components: {
          main: () => importPage("content/index"),
        },
        meta: {
          title: "内容管理",
          primaryMenuId: "content",
          secondaryMenuId: "index",
        },
      },
      {
        path: "siteEdit",
        components: {
          main: () => importPage("content/siteEdit"),
        },
        meta: {
          title: "站点设置",
          primaryMenuId: "content",
          secondaryMenuId: "siteEdit",
        },
      },
      {
        path: "columnEdit",
        components: {
          main: () => importPage("content/columnEdit"),
        },
        meta: {
          title: "栏目设置",
          primaryMenuId: "content",
          secondaryMenuId: "columnEdit",
        },
      },
      {
        path: "contentList",
        components: {
          main: () => importPage("content/contentList"),
        },
        meta: {
          title: "栏目信息",
          primaryMenuId: "content",
          secondaryMenuId: "contentList",
        },
      },
      {
        path: "recycleList",
        components: {
          main: () => importPage("content/recycleList"),
        },
        meta: {
          title: "信息回收站",
          primaryMenuId: "content",
          secondaryMenuId: "contentList",
        },
      },
      {
        path: "contentEdit",
        components: {
          main: () => importPage("content/contentEdit"),
        },
        meta: {
          title: "栏目信息-编辑",
          primaryMenuId: "content",
          secondaryMenuId: "contentList",
        },
      },
      {
        path: "contentDetail",
        components: {
          main: () => importPage("content/contentDetail"),
        },
        meta: {
          title: "栏目信息-详情",
          primaryMenuId: "content",
          secondaryMenuId: "contentDetail",
        },
      },
      {
        path: "singleEdit",
        components: {
          main: () => importPage("content/singleEdit"),
        },
        meta: {
          title: "栏目信息-编辑",
          primaryMenuId: "content",
          secondaryMenuId: "singleEdit",
        },
      },
    ],
  },
  // ===== 文件管理 =====
  {
    path: "/file",
    component: () => importLayout("mainLayout"),
    children: [
      {
        path: "index",
        components: {
          main: () => importPage("file/index"),
        },
        meta: {
          title: "文件管理",
          primaryMenuId: "file",
          secondaryMenuId: "index",
        },
      },
    ],
  },
  // ===== 系统设置 =====
  {
    path: "/setting",
    component: () => importLayout("settingLayout"),
    children: [
      {
        path: "systemSetting",
        components: {
          main: () => importPage("setting/systemSetting"),
        },
        meta: {
          title: "系统设置",
          primaryMenuId: "setting",
          secondaryMenuId: "systemSetting",
        },
      },
      {
        path: "fileSetting",
        components: {
          main: () => importPage("setting/fileSetting"),
        },
        meta: {
          title: "附件设置",
          primaryMenuId: "setting",
          secondaryMenuId: "fileSetting",
        },
      },
      {
        path: "regionList",
        components: {
          main: () => importPage("setting/regionList"),
        },
        meta: {
          title: "地区设置",
          primaryMenuId: "setting",
          secondaryMenuId: "regionList",
        },
      },
      {
        path: "regionEdit",
        components: {
          main: () => importPage("setting/regionEdit"),
        },
        meta: {
          title: "地区设置-编辑",
          primaryMenuId: "setting",
          secondaryMenuId: "regionList",
        },
      },
      {
        path: "sensitiveWordList",
        components: {
          main: () => importPage("setting/sensitiveWordList"),
        },
        meta: {
          title: "内容敏感词",
          primaryMenuId: "setting",
          secondaryMenuId: "sensitiveWordList",
        },
      },
      {
        path: "sensitiveWordEdit",
        components: {
          main: () => importPage("setting/sensitiveWordEdit"),
        },
        meta: {
          title: "内容敏感词-编辑",
          primaryMenuId: "setting",
          secondaryMenuId: "sensitiveWordList",
        },
      },
      {
        path: "sensitiveWordAddMultiple",
        components: {
          main: () => importPage("setting/sensitiveWordAddMultiple"),
        },
        meta: {
          title: "内容敏感词-批量添加",
          primaryMenuId: "setting",
          secondaryMenuId: "sensitiveWordList",
        },
      },
      {
        path: "mailSetting",
        components: {
          main: () => importPage("setting/mailSetting"),
        },
        meta: {
          title: "邮箱设置",
          primaryMenuId: "setting",
          secondaryMenuId: "mailSetting",
        },
      },
      {
        path: "serviceSetting",
        components: {
          main: () => importPage("setting/serviceSetting"),
        },
        meta: {
          title: "在线客服",
          primaryMenuId: "setting",
          secondaryMenuId: "serviceSetting",
        },
      },
      {
        path: "statisticSetting",
        components: {
          main: () => importPage("setting/statisticSetting"),
        },
        meta: {
          title: "流量统计",
          primaryMenuId: "setting",
          secondaryMenuId: "statisticSetting",
        },
      },
      {
        path: "systemInfo",
        components: {
          main: () => importPage("setting/systemInfo"),
        },
        meta: {
          title: "系统信息",
          primaryMenuId: "setting",
          secondaryMenuId: "systemInfo",
        },
      },
    ],
  },
  // ===== 安全设置 =====
  {
    path: "/security",
    component: () => importLayout("securityLayout"),
    children: [
      {
        path: "securitySetting",
        components: {
          main: () => importPage("security/securitySetting"),
        },
        meta: {
          title: "安全配置",
          primaryMenuId: "security",
          secondaryMenuId: "securitySetting",
        },
      },
      {
        path: "logList",
        components: {
          main: () => importPage("security/logList"),
        },
        meta: {
          title: "系统日志",
          primaryMenuId: "security",
          secondaryMenuId: "logList",
        },
      },
    ],
  },
  // ===== 安全设置 - 用户管理 =====
  {
    path: "/manager",
    component: () => importLayout("securityLayout"),
    children: [
      {
        path: "managerList",
        components: {
          main: () => importPage("manager/managerList"),
        },
        meta: {
          title: "用户管理",
          primaryMenuId: "security",
          secondaryMenuId: "managerList",
          tertiaryMenuId: "managerList",
        },
      },
      {
        path: "managerEdit",
        components: {
          main: () => importPage("manager/managerEdit"),
        },
        meta: {
          title: "用户管理-编辑",
          primaryMenuId: "security",
          secondaryMenuId: "managerList",
          tertiaryMenuId: "managerList",
        },
      },
      {
        path: "roleList",
        components: {
          main: () => importPage("manager/roleList"),
        },
        meta: {
          title: "角色管理",
          primaryMenuId: "security",
          secondaryMenuId: "managerList",
          tertiaryMenuId: "roleList",
        },
      },
      {
        path: "roleEdit",
        components: {
          main: () => importPage("manager/roleEdit"),
        },
        meta: {
          title: "角色管理-编辑",
          primaryMenuId: "security",
          secondaryMenuId: "managerList",
          tertiaryMenuId: "roleList",
        },
      },
      {
        path: "menuPermission",
        components: {
          main: () => importPage("manager/menuPermission"),
        },
        meta: {
          title: "角色管理-设置菜单权限",
          primaryMenuId: "security",
          secondaryMenuId: "managerList",
          tertiaryMenuId: "roleList",
        },
      },
      {
        path: "roleKoqianPermission",
        components: {
          main: () => importPage("manager/roleKoqianPermission"),
        },
        meta: {
          title: "角色管理-设置兰麦考前权限",
          primaryMenuId: "security",
          secondaryMenuId: "managerList",
          tertiaryMenuId: "roleList",
        },
      },
      {
        path: "roleHypxPermission",
        components: {
          main: () => importPage("manager/roleHypxPermission"),
        },
        meta: {
          title: "角色管理-设置行业培训权限",
          primaryMenuId: "security",
          secondaryMenuId: "managerList",
          tertiaryMenuId: "roleList",
        },
      },
      {
        path: "rolewebPermission",
        components: {
          main: () => importPage("manager/rolewebPermission"),
        },
        meta: {
          title: "角色管理-设置官网权限",
          primaryMenuId: "security",
          secondaryMenuId: "managerList",
          tertiaryMenuId: "roleList",
        },
      },
      {
        path: "permissionList",
        components: {
          main: () => importPage("manager/permissionList"),
        },
        meta: {
          title: "自定义权限(兰麦考前)",
          primaryMenuId: "security",
          secondaryMenuId: "managerList",
          tertiaryMenuId: "permissionList",
        },
      },
      {
        path: "permissionEdit",
        components: {
          main: () => importPage("manager/permissionEdit"),
        },
        meta: {
          title: "自定义权限(兰麦考前)-编辑",
          primaryMenuId: "security",
          secondaryMenuId: "managerList",
          tertiaryMenuId: "permissionList",
        },
      },
      {
        path: "permissionHypxList",
        components: {
          main: () => importPage("manager/permissionHypxList"),
        },
        meta: {
          title: "自定义权限(行业培训)",
          primaryMenuId: "security",
          secondaryMenuId: "managerList",
          tertiaryMenuId: "permissionHypxList",
        },
      },
      {
        path: "permissionHypxEdit",
        components: {
          main: () => importPage("manager/permissionHypxEdit"),
        },
        meta: {
          title: "自定义权限(行业培训)-编辑",
          primaryMenuId: "security",
          secondaryMenuId: "managerList",
          tertiaryMenuId: "permissionHypxList",
        },
      },
      {
        path: "permissionWebList",
        components: {
          main: () => importPage("manager/permissionWebList"),
        },
        meta: {
          title: "自定义权限(官网)",
          primaryMenuId: "security",
          secondaryMenuId: "managerList",
          tertiaryMenuId: "permissionWebList",
        },
      },
      {
        path: "permissionWebEdit",
        components: {
          main: () => importPage("manager/permissionWebEdit"),
        },
        meta: {
          title: "自定义权限(官网)-编辑",
          primaryMenuId: "security",
          secondaryMenuId: "managerList",
          tertiaryMenuId: "permissionWebList",
        },
      },
      {
        path: "permissionMenuList",
        components: {
          main: () => importPage("manager/permissionMenuList"),
        },
        meta: {
          title: "自定义权限(菜单)",
          primaryMenuId: "security",
          secondaryMenuId: "managerList",
          tertiaryMenuId: "permissionMenuList",
        },
      },
      {
        path: "permissionMenuEdit",
        components: {
          main: () => importPage("manager/permissionMenuEdit"),
        },
        meta: {
          title: "自定义权限(菜单)-编辑",
          primaryMenuId: "security",
          secondaryMenuId: "managerList",
          tertiaryMenuId: "permissionMenuList",
        },
      },
    ],
  },
  // ===== 管理员登录 =====
  {
    path: "/manager",
    component: () => importLayout("emptyLayout"),
    children: [
      {
        path: "login",
        components: {
          main: () => importPage("manager/login"),
        },
        meta: {
          title: "登录",
        },
      },
    ],
  },
  // ===== 开发设置 =====
  {
    path: "/develop",
    component: () => importLayout("developLayout"),
    children: [
      {
        path: "siteList",
        components: {
          main: () => importPage("develop/siteList"),
        },
        meta: {
          title: "栏目管理",
          primaryMenuId: "develop",
          secondaryMenuId: "siteList",
        },
      },
      {
        path: "siteEdit",
        components: {
          main: () => importPage("develop/siteEdit"),
        },
        meta: {
          title: "站点栏目管理-站点编辑",
          primaryMenuId: "develop",
          secondaryMenuId: "siteList",
        },
      },
      {
        path: "channelEdit",
        components: {
          main: () => importPage("develop/channelEdit"),
        },
        meta: {
          title: "站点栏目管理-栏目编辑",
          primaryMenuId: "develop",
          secondaryMenuId: "siteList",
        },
      },
      {
        path: "formList",
        components: {
          main: () => importPage("develop/formList"),
        },
        meta: {
          title: "表单模型管理",
          primaryMenuId: "develop",
          secondaryMenuId: "formList",
        },
      },
      {
        path: "formEdit",
        components: {
          main: () => importPage("develop/formEdit"),
        },
        meta: {
          title: "表单模型管理-编辑",
          primaryMenuId: "develop",
          secondaryMenuId: "formList",
        },
      },
      {
        path: "tableList",
        components: {
          main: () => importPage("develop/tableList"),
        },
        meta: {
          title: "表模型管理管理",
          primaryMenuId: "develop",
          secondaryMenuId: "tableList",
        },
      },
      {
        path: "tableEdit",
        components: {
          main: () => importPage("develop/tableEdit"),
        },
        meta: {
          title: "表模型管理管理-编辑",
          primaryMenuId: "develop",
          secondaryMenuId: "tableList",
        },
      },
    ],
  },

  // ===== 教育培训 =====
  {
    path: "/training",
    component: () => importLayout("trainingLayout"),
    children: [
      {
        path: "wxNoticeList",
        components: {
          main: () => importPage("training/wxNoticeList"),
        },
        meta: {
          title: "通知消息",
          primaryMenuId: "training",
          secondaryMenuId: "wxNoticeList",
          tertiaryMenuId: "wxNoticeList",
        },
      },
      {
        path: "wxNoticeEdit",
        components: {
          main: () => importPage("training/wxNoticeEdit"),
        },
        meta: {
          title: "通知消息-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "wxNoticeList",
          tertiaryMenuId: "wxNoticeList",
        },
      },
      {
        path: "wxNavigationList",
        components: {
          main: () => importPage("training/wxNavigationList"),
        },
        meta: {
          title: "首页快捷导航",
          primaryMenuId: "training",
          secondaryMenuId: "wxNavigationList",
          tertiaryMenuId: "wxNavigationList",
        },
      },
      {
        path: "wxNavigationEdit",
        components: {
          main: () => importPage("training/wxNavigationEdit"),
        },
        meta: {
          title: "快捷导航-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "wxNavigationList",
          tertiaryMenuId: "wxNavigationList",
        },
      },
      {
        path: "wxBannerList",
        components: {
          main: () => importPage("training/wxBannerList"),
        },
        meta: {
          title: "小程序首页轮播",
          primaryMenuId: "training",
          secondaryMenuId: "wxBannerList",
          tertiaryMenuId: "wxBannerList",
        },
      },
      {
        path: "wxBannerEdit",
        components: {
          main: () => importPage("training/wxBannerEdit"),
        },
        meta: {
          title: "小程序首页轮播图-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "wxBannerList",
          tertiaryMenuId: "wxBannerList",
        },
      },
      {
        path: "treatyList",
        components: {
          main: () => importPage("training/treatyList"),
        },
        meta: {
          title: "协议管理",
          primaryMenuId: "training",
          secondaryMenuId: "treatyList",
          tertiaryMenuId: "treatyList",
        },
      },
      {
        path: "treatyEdit",
        components: {
          main: () => importPage("training/treatyEdit"),
        },
        meta: {
          title: "协议管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "treatyList",
          tertiaryMenuId: "treatyList",
        },
      },
      {
        path: "treatyContentList",
        components: {
          main: () => importPage("training/treatyContentList"),
        },
        meta: {
          title: "协议内容管理",
          primaryMenuId: "training",
          secondaryMenuId: "treatyContentList",
          tertiaryMenuId: "treatyContentList",
        },
      },
      {
        path: "treatyContentEdit",
        components: {
          main: () => importPage("training/treatyContentEdit"),
        },
        meta: {
          title: "协议内容-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "treatyContentList",
          tertiaryMenuId: "treatyContentList",
        },
      },
      {
        path: "bannerList",
        components: {
          main: () => importPage("training/bannerList"),
        },
        meta: {
          title: "首页轮播图",
          primaryMenuId: "training",
          secondaryMenuId: "bannerList",
          tertiaryMenuId: "bannerList",
        },
      },
      {
        path: "bannerEdit",
        components: {
          main: () => importPage("training/bannerEdit"),
        },
        meta: {
          title: "首页轮播图-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "bannerList",
          tertiaryMenuId: "bannerList",
        },
      },
      {
        path: "topBannerList",
        components: {
          main: () => importPage("training/topBannerList"),
        },
        meta: {
          title: "顶部轮播图",
          primaryMenuId: "training",
          secondaryMenuId: "topBannerList",
          tertiaryMenuId: "topBannerList",
        },
      },
      {
        path: "topBannerEdit",
        components: {
          main: () => importPage("training/topBannerEdit"),
        },
        meta: {
          title: "顶部轮播图-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "topBannerList",
          tertiaryMenuId: "topBannerList",
        },
      },
      {
        path: "ad",
        components: {
          main: () => importPage("training/ad"),
        },
        meta: {
          title: "飘窗广告",
          primaryMenuId: "training",
          secondaryMenuId: "ad",
          tertiaryMenuId: "ad",
        },
      },
      {
        path: "saleBannerList",
        components: {
          main: () => importPage("training/saleBannerList"),
        },
        meta: {
          title: "促销广告",
          primaryMenuId: "training",
          secondaryMenuId: "bannerList",
          tertiaryMenuId: "saleBannerList",
        },
      },
      {
        path: "saleBannerEdit",
        components: {
          main: () => importPage("training/saleBannerEdit"),
        },
        meta: {
          title: "促销广告-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "bannerList",
          tertiaryMenuId: "saleBannerList",
        },
      },
      {
        path: "classIntroductionList",
        components: {
          main: () => importPage("training/classIntroductionList"),
        },
        meta: {
          title: "班型介绍",
          primaryMenuId: "training",
          secondaryMenuId: "bannerList",
          tertiaryMenuId: "classIntroductionList",
        },
      },
      {
        path: "classIntroductionEdit",
        components: {
          main: () => importPage("training/classIntroductionEdit"),
        },
        meta: {
          title: "班型介绍-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "bannerList",
          tertiaryMenuId: "classIntroductionList",
        },
      },
      {
        path: "classIntroductionCategoryList",
        components: {
          main: () => importPage("training/classIntroductionCategoryList"),
        },
        meta: {
          title: "班型介绍类别",
          primaryMenuId: "training",
          secondaryMenuId: "bannerList",
          tertiaryMenuId: "classIntroductionCategoryList",
        },
      },
      {
        path: "classIntroductionCategoryEdit",
        components: {
          main: () => importPage("training/classIntroductionCategoryEdit"),
        },
        meta: {
          title: "班型介绍类别-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "bannerList",
          tertiaryMenuId: "classIntroductionCategoryList",
        },
      },
      {
        path: "rediangzList",
        components: {
          main: () => importPage("training/rediangzList"),
        },
        meta: {
          title: "热点关注",
          primaryMenuId: "training",
          secondaryMenuId: "bannerList",
          tertiaryMenuId: "rediangzList",
        },
      },
      {
        path: "rediangzEdit",
        components: {
          main: () => importPage("training/rediangzEdit"),
        },
        meta: {
          title: "热点关注-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "bannerList",
          tertiaryMenuId: "rediangzList",
        },
      },
      {
        path: "searchKeywordsList",
        components: {
          main: () => importPage("training/searchKeywordsList"),
        },
        meta: {
          title: "搜索关键字",
          primaryMenuId: "training",
          secondaryMenuId: "lecturerList",
          tertiaryMenuId: "searchKeywordsList",
        },
      },
      {
        path: "searchKeywordsEdit",
        components: {
          main: () => importPage("training/searchKeywordsEdit"),
        },
        meta: {
          title: "搜索关键字-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "lecturerList",
          tertiaryMenuId: "searchKeywordsList",
        },
      },
      {
        path: "wenjuanList",
        components: {
          main: () => importPage("training/wenjuanList"),
        },
        meta: {
          title: "调查问卷",
          primaryMenuId: "training",
          secondaryMenuId: "lecturerList",
          tertiaryMenuId: "wenjuanList",
        },
      },
      {
        path: "wenjuanEdit",
        components: {
          main: () => importPage("training/wenjuanEdit"),
        },
        meta: {
          title: "调查问卷-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "lecturerList",
          tertiaryMenuId: "wenjuanList",
        },
      },
      {
        path: "industryCoursesList",
        components: {
          main: () => importPage("training/industryCoursesList"),
        },
        meta: {
          title: "业内课程",
          primaryMenuId: "training",
          secondaryMenuId: "bannerList",
          tertiaryMenuId: "industryCoursesList",
        },
      },
      {
        path: "industryCoursesEdit",
        components: {
          main: () => importPage("training/industryCoursesEdit"),
        },
        meta: {
          title: "业内课程-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "bannerList",
          tertiaryMenuId: "industryCoursesList",
        },
      },
      {
        path: "bannerListHd",
        components: {
          main: () => importPage("training/bannerListHd"),
        },
        meta: {
          title: "首页底部banner",
          primaryMenuId: "training",
          secondaryMenuId: "bannerList",
          tertiaryMenuId: "bannerListHd",
        },
      },
      {
        path: "bannerEditHd",
        components: {
          main: () => importPage("training/bannerEditHd"),
        },
        meta: {
          title: "首页底部banner-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "bannerList",
          tertiaryMenuId: "bannerListHd",
        },
      },
      {
        path: "webInfo",
        components: {
          main: () => importPage("training/webInfo"),
        },
        meta: {
          title: "网站基本信息",
          primaryMenuId: "training",
          secondaryMenuId: "bannerList",
          tertiaryMenuId: "webInfo",
        },
      },
      {
        path: "aboutUs",
        components: {
          main: () => importPage("training/aboutUs"),
        },
        meta: {
          title: "关于我们",
          primaryMenuId: "training",
          secondaryMenuId: "lecturerList",
          tertiaryMenuId: "aboutUs",
        },
      },
      {
        path: "contactUs",
        components: {
          main: () => importPage("training/contactUs"),
        },
        meta: {
          title: "联系我们",
          primaryMenuId: "training",
          secondaryMenuId: "lecturerList",
          tertiaryMenuId: "contactUs",
        },
      },
      {
        path: "linkList",
        components: {
          main: () => importPage("training/linkList"),
        },
        meta: {
          title: "友情链接",
          primaryMenuId: "training",
          secondaryMenuId: "lecturerList",
          tertiaryMenuId: "linkList",
        },
      },
      {
        path: "linkEdit",
        components: {
          main: () => importPage("training/linkEdit"),
        },
        meta: {
          title: "友情链接-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "lecturerList",
          tertiaryMenuId: "linkList",
        },
      },
      {
        path: "legalNotice",
        components: {
          main: () => importPage("training/legalNotice"),
        },
        meta: {
          title: "法律声明",
          primaryMenuId: "training",
          secondaryMenuId: "lecturerList",
          tertiaryMenuId: "legalNotice",
        },
      },
      
      {
        path: "lecturerList",
        components: {
          main: () => importPage("training/lecturerList"),
        },
        meta: {
          title: "讲师管理",
          primaryMenuId: "training",
          secondaryMenuId: "lecturerList",
          tertiaryMenuId: "lecturerList",
        },
      },
      {
        path: "lecturerEdit",
        components: {
          main: () => importPage("training/lecturerEdit"),
        },
        meta: {
          title: "讲师管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "lecturerList",
          tertiaryMenuId: "lecturerList",
        },
      },
      {
        path: "lanmuList",
        components: {
          main: () => importPage("training/lanmuList"),
        },
        meta: {
          title: "栏目信息",
          primaryMenuId: "training",
          secondaryMenuId: "lecturerList",
          tertiaryMenuId: "lanmuList",
        },
      },
      {
        path: "lanmuEdit",
        components: {
          main: () => importPage("training/lanmuEdit"),
        },
        meta: {
          title: "栏目信息-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "lecturerList",
          tertiaryMenuId: "lanmuList",
        },
      },
      {
        path: "annexList",
        components: {
          main: () => importPage("training/annexList"),
        },
        meta: {
          title: "课程附件管理",
          primaryMenuId: "training",
          secondaryMenuId: "curriculumList",
          tertiaryMenuId: "annexList",
        },
      },
      {
        path: "annexEdit",
        components: {
          main: () => importPage("training/annexEdit"),
        },
        meta: {
          title: "课程附件管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "curriculumList",
          tertiaryMenuId: "annexList",
        },
      },
      {
        path: "courseshipinList",
        components: {
          main: () => importPage("training/courseshipinList"),
        },
        meta: {
          title: "课程视频管理",
          primaryMenuId: "training",
          secondaryMenuId: "curriculumList",
          tertiaryMenuId: "courseshipinList",
        },
      },
      {
        path: "courseshipinEdit",
        components: {
          main: () => importPage("training/courseshipinEdit"),
        },
        meta: {
          title: "课程视频管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "curriculumList",
          tertiaryMenuId: "courseshipinList",
        },
      },
      {
        path: "commentList",
        components: {
          main: () => importPage("training/commentList"),
        },
        meta: {
          title: "课程评价管理",
          primaryMenuId: "training",
          secondaryMenuId: "curriculumList",
          tertiaryMenuId: "commentList",
        },
      },
      {
        path: "commentEdit",
        components: {
          main: () => importPage("training/commentEdit"),
        },
        meta: {
          title: "课程评价管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "curriculumList",
          tertiaryMenuId: "commentList",
        },
      },
      {
        path: "excellentAnswersList",
        components: {
          main: () => importPage("training/excellentAnswersList"),
        },
        meta: {
          title: "精品问答管理",
          primaryMenuId: "training",
          secondaryMenuId: "curriculumList",
          tertiaryMenuId: "excellentAnswersList",
        },
      },
      {
        path: "excellentAnswersEdit",
        components: {
          main: () => importPage("training/excellentAnswersEdit"),
        },
        meta: {
          title: "精品问答管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "curriculumList",
          tertiaryMenuId: "excellentAnswersList",
        },
      },
      {
        path: "courseCollectionList",
        components: {
          main: () => importPage("training/courseCollectionList"),
        },
        meta: {
          title: "课程收藏管理",
          primaryMenuId: "training",
          secondaryMenuId: "curriculumList",
          tertiaryMenuId: "courseCollectionList",
        },
      },
      {
        path: "courseCollectionEdit",
        components: {
          main: () => importPage("training/courseCollectionEdit"),
        },
        meta: {
          title: "课程收藏管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "curriculumList",
          tertiaryMenuId: "courseCollectionList",
        },
      },
      {
        path: "studentList",
        components: {
          main: () => importPage("training/studentList"),
        },
        meta: {
          title: "学员管理",
          primaryMenuId: "training",
          secondaryMenuId: "studentList",
          tertiaryMenuId: "studentList",
        },
      },
      {
        path: "studentEdit",
        components: {
          main: () => importPage("training/studentEdit"),
        },
        meta: {
          title: "学员管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "studentList",
          tertiaryMenuId: "studentList",
        },
      },
      {
        path: "studentGroupList",
        components: {
          main: () => importPage("training/studentGroupList"),
        },
        meta: {
          title: "学员分组管理",
          primaryMenuId: "training",
          secondaryMenuId: "studentList",
          tertiaryMenuId: "studentGroupList",
        },
      },
      {
        path: "studentGroupEdit",
        components: {
          main: () => importPage("training/studentGroupEdit"),
        },
        meta: {
          title: "学员分组管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "studentList",
          tertiaryMenuId: "studentGroupList",
        },
      },
      {
        path: "examinationRecordList",
        components: {
          main: () => importPage("training/examinationRecordList"),
        },
        meta: {
          title: "考试记录管理",
          primaryMenuId: "training",
          secondaryMenuId: "studentList",
          tertiaryMenuId: "examinationRecordList",
        },
      },
      {
        path: "examinationRecordEdit",
        components: {
          main: () => importPage("training/examinationRecordEdit"),
        },
        meta: {
          title: "考试记录管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "studentList",
          tertiaryMenuId: "examinationRecordList",
        },
      },
      {
        path: "wenjuanRecordList",
        components: {
          main: () => importPage("training/wenjuanRecordList"),
        },
        meta: {
          title: "调查问卷记录",
          primaryMenuId: "training",
          secondaryMenuId: "studentList",
          tertiaryMenuId: "wenjuanRecordList",
        },
      },
      {
        path: "mistakesCollectionList",
        components: {
          main: () => importPage("training/mistakesCollectionList"),
        },
        meta: {
          title: "错题集管理",
          primaryMenuId: "training",
          secondaryMenuId: "studentList",
          tertiaryMenuId: "mistakesCollectionList",
        },
      },
      {
        path: "courseLearningRecordList",
        components: {
          main: () => importPage("training/courseLearningRecordList"),
        },
        meta: {
          title: "课程学习记录管理",
          primaryMenuId: "training",
          secondaryMenuId: "studentList",
          tertiaryMenuId: "courseLearningRecordList",
        },
      },
      {
        path: "courseLearningRecordEdit",
        components: {
          main: () => importPage("training/courseLearningRecordEdit"),
        },
        meta: {
          title: "课程学习记录管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "studentList",
          tertiaryMenuId: "courseLearningRecordList",
        },
      },
      {
        path: "brushrecordList",
        components: {
          main: () => importPage("training/brushrecordList"),
        },
        meta: {
          title: "课程刷题记录管理",
          primaryMenuId: "training",
          secondaryMenuId: "studentList",
          tertiaryMenuId: "brushrecordList",
        },
      },
      {
        path: "brushrecordEdit",
        components: {
          main: () => importPage("training/brushrecordEdit"),
        },
        meta: {
          title: "课程刷题记录管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "studentList",
          tertiaryMenuId: "brushrecordList",
        },
      },
      {
        path: "simulationrecordList",
        components: {
          main: () => importPage("training/simulationrecordList"),
        },
        meta: {
          title: "模拟练习记录管理",
          primaryMenuId: "training",
          secondaryMenuId: "studentList",
          tertiaryMenuId: "simulationrecordList",
        },
      },
      {
        path: "simulationrecordEdit",
        components: {
          main: () => importPage("training/simulationrecordEdit"),
        },
        meta: {
          title: "模拟练习记录管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "studentList",
          tertiaryMenuId: "simulationrecordList",
        },
      },
      {
        path: "topiccollectList",
        components: {
          main: () => importPage("training/topiccollectList"),
        },
        meta: {
          title: "我的收藏管理",
          primaryMenuId: "training",
          secondaryMenuId: "studentList",
          tertiaryMenuId: "topiccollectList",
        },
      },
      {
        path: "topiccollectEdit",
        components: {
          main: () => importPage("training/topiccollectEdit"),
        },
        meta: {
          title: "我的收藏管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "studentList",
          tertiaryMenuId: "topiccollectList",
        },
      },
      {
        path: "kaiketixingList",
        components: {
          main: () => importPage("training/kaiketixingList"),
        },
        meta: {
          title: "开课提醒",
          primaryMenuId: "training",
          secondaryMenuId: "studentList",
          tertiaryMenuId: "kaiketixingList",
        },
      },
      {
        path: "kaiketixingEdit",
        components: {
          main: () => importPage("training/kaiketixingEdit"),
        },
        meta: {
          title: "开课提醒-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "studentList",
          tertiaryMenuId: "kaiketixingList",
        },
      },
      {
        path: "xitongtzList",
        components: {
          main: () => importPage("training/xitongtzList"),
        },
        meta: {
          title: "系统通知",
          primaryMenuId: "training",
          secondaryMenuId: "studentList",
          tertiaryMenuId: "xitongtzList",
        },
      },
      {
        path: "xitongtzEdit",
        components: {
          main: () => importPage("training/xitongtzEdit"),
        },
        meta: {
          title: "系统通知-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "studentList",
          tertiaryMenuId: "xitongtzList",
        },
      },
      {
        path: "ProblemfeedbackList",
        components: {
          main: () => importPage("training/ProblemfeedbackList"),
        },
        meta: {
          title: "问题反馈管理",
          primaryMenuId: "training",
          secondaryMenuId: "studentList",
          tertiaryMenuId: "ProblemfeedbackList",
        },
      },
      {
        path: "ProblemfeedbackEdit",
        components: {
          main: () => importPage("training/ProblemfeedbackEdit"),
        },
        meta: {
          title: "问题反馈管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "studentList",
          tertiaryMenuId: "ProblemfeedbackList",
        },
      },
      {
        path: "exambookList",
        components: {
          main: () => importPage("training/exambookList"),
        },
        meta: {
          title: "考试用书及视频管理",
          primaryMenuId: "training",
          secondaryMenuId: "exambookList",
          tertiaryMenuId: "exambookList",
        },
      },
      {
        path: "exambookEdit",
        components: {
          main: () => importPage("training/exambookEdit"),
        },
        meta: {
          title: "考试用书及视频管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "exambookList",
          tertiaryMenuId: "exambookList",
        },
      },
      {
        path: "courseCatalogBList",
        components: {
          main: () => importPage("training/courseCatalogBList"),
        },
        meta: {
          title: "考试用书目录管理",
          primaryMenuId: "training",
          secondaryMenuId: "exambookList",
          tertiaryMenuId: "courseCatalogBList",
        },
      },
      {
        path: "courseCatalogBEdit",
        components: {
          main: () => importPage("training/courseCatalogBEdit"),
        },
        meta: {
          title: "考试用书目录管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "exambookList",
          tertiaryMenuId: "courseCatalogBList",
        },
      },
      {
        path: "coursesectionsBList",
        components: {
          main: () => importPage("training/coursesectionsBList"),
        },
        meta: {
          title: "考试用书章节管理",
          primaryMenuId: "training",
          secondaryMenuId: "exambookList",
          tertiaryMenuId: "coursesectionsBList",
        },
      },
      {
        path: "coursesectionsBEdit",
        components: {
          main: () => importPage("training/coursesectionsBEdit"),
        },
        meta: {
          title: "考试用书章节管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "exambookList",
          tertiaryMenuId: "coursesectionsBList",
        },
      },
      {
        path: "receiveBookLog",
        components: {
          main: () => importPage("training/receiveBookLog"),
        },
        meta: {
          title: "考试用书-用户领取记录",
          primaryMenuId: "training",
          secondaryMenuId: "exambookList",
          tertiaryMenuId: "receiveBookLog",
        },
      },
      {
        path: "curriculumList",
        components: {
          main: () => importPage("training/curriculumList"),
        },
        meta: {
          title: "全部课程管理",
          primaryMenuId: "training",
          secondaryMenuId: "curriculumList",
          tertiaryMenuId: "curriculumList",
        },
      },
      {
        path: "curriculumEdit",
        components: {
          main: () => importPage("training/curriculumEdit"),
        },
        meta: {
          title: "全部课程-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "curriculumList",
          tertiaryMenuId: "curriculumList",
        },
      },
      {
        path: "courseCatalogKCList",
        components: {
          main: () => importPage("training/courseCatalogKCList"),
        },
        meta: {
          title: "全部课程目录管理",
          primaryMenuId: "training",
          secondaryMenuId: "curriculumList",
          tertiaryMenuId: "courseCatalogKCList",
        },
      },
      {
        path: "courseCatalogKCEdit",
        components: {
          main: () => importPage("training/courseCatalogKCEdit"),
        },
        meta: {
          title: "全部课程目录管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "curriculumList",
          tertiaryMenuId: "courseCatalogKCList",
        },
      },
      {
        path: "discountList",
        components: {
          main: () => importPage("training/discountList"),
        },
        meta: {
          title: "折扣管理",
          primaryMenuId: "training",
          secondaryMenuId: "curriculumList",
          tertiaryMenuId: "discountList",
        },
      },
      {
        path: "discountEdit",
        components: {
          main: () => importPage("training/discountEdit"),
        },
        meta: {
          title: "折扣管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "curriculumList",
          tertiaryMenuId: "discountList",
        },
      },
      {
        path: "singleDiscount",
        components: {
          main: () => importPage("training/singleDiscount"),
        },
        meta: {
          title: "单门课程折扣管理",
          primaryMenuId: "training",
          secondaryMenuId: "curriculumList",
          tertiaryMenuId: "singleDiscount",
        },
      },      
      {
        path: "courseDiscountList",
        components: {
          main: () => importPage("training/courseDiscountList"),
        },
        meta: {
          title: "全部课程折扣管理",
          primaryMenuId: "training",
          secondaryMenuId: "curriculumList",
          tertiaryMenuId: "courseDiscountList",
        },
      },
      {
        path: "courseDiscountEdit",
        components: {
          main: () => importPage("training/courseDiscountEdit"),
        },
        meta: {
          title: "全部课程折扣管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "curriculumList",
          tertiaryMenuId: "courseDiscountList",
        },
      },
      {
        path: "couponDiscount",
        components: {
          main: () => importPage("training/couponDiscount"),
        },
        meta: {
          title: "优惠券管理",
          primaryMenuId: "training",
          secondaryMenuId: "curriculumList",
          tertiaryMenuId: "couponDiscount",
        },
      },
      {
        path: "couponDiscountEdit",
        components: {
          main: () => importPage("training/couponDiscountEdit"),
        },
        meta: {
          title: "优惠券管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "curriculumList",
          tertiaryMenuId: "couponDiscount",
        },
      },
      {
        path: "coursesectionsKCList",
        components: {
          main: () => importPage("training/coursesectionsKCList"),
        },
        meta: {
          title: "全部课程章节管理",
          primaryMenuId: "training",
          secondaryMenuId: "curriculumList",
          tertiaryMenuId: "coursesectionsKCList",
        },
      },
      {
        path: "coursesectionsKCEdit",
        components: {
          main: () => importPage("training/coursesectionsKCEdit"),
        },
        meta: {
          title: "全部课程章节管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "curriculumList",
          tertiaryMenuId: "coursesectionsKCList",
        },
      },
      {
        path: "courseQrCodeList",
        components: {
          main: () => importPage("training/courseQrCodeList"),
        },
        meta: {
          title: "全部课程二维码管理",
          primaryMenuId: "training",
          secondaryMenuId: "exambookList",
          tertiaryMenuId: "courseQrCodeList",
        },
      },
      {
        path: "courseQrCodeEdit",
        components: {
          main: () => importPage("training/courseQrCodeEdit"),
        },
        meta: {
          title: "全部课程二维码管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "exambookList",
          tertiaryMenuId: "courseQrCodeList",
        },
      },
      {
        path: "freedataKemuList",
        components: {
          main: () => importPage("training/freedataKemuList"),
        },
        meta: {
          title: "学习资料科目管理",
          primaryMenuId: "training",
          secondaryMenuId: "freedataList",
          tertiaryMenuId: "freedataKemuList",
        },
      },      
      {
        path: "freedataKemuEdit",
        components: {
          main: () => importPage("training/freedataKemuEdit"),
        },
        meta: {
          title: "学习资料科目管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "freedataList",
          tertiaryMenuId: "freedataKemuList",
        },
      },
      {
        path: "freedataFeeList",
        components: {
          main: () => importPage("training/freedataFeeList"),
        },
        meta: {
          title: "学习资料费用分类",
          primaryMenuId: "training",
          secondaryMenuId: "freedataList",
          tertiaryMenuId: "freedataFeeList",
        },
      },
      {
        path: "freedataFeeEdit",
        components: {
          main: () => importPage("training/freedataFeeEdit"),
        },
        meta: {
          title: "学习资料费用类别-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "freedataList",
          tertiaryMenuId: "freedataFeeList",
        },
      },
      {
        path: "freedataList",
        components: {
          main: () => importPage("training/freedataList"),
        },
        meta: {
          title: "学习资料管理",
          primaryMenuId: "training",
          secondaryMenuId: "freedataList",
          tertiaryMenuId: "freedataList",
        },
      },
      {
        path: "freedataEdit",
        components: {
          main: () => importPage("training/freedataEdit"),
        },
        meta: {
          title: "学习资料管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "freedataList",
          tertiaryMenuId: "freedataList",
        },
      },
      {
        path: "freetestingList",
        components: {
          main: () => importPage("training/freetestingList"),
        },
        meta: {
          title: "免费测试管理",
          primaryMenuId: "training",
          secondaryMenuId: "freetestingList",
          tertiaryMenuId: "freetestingList",
        },
      },
      {
        path: "freetestingEdit",
        components: {
          main: () => importPage("training/freetestingEdit"),
        },
        meta: {
          title: "免费测试管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "freetestingList",
          tertiaryMenuId: "freetestingList",
        },
      },
      {
        path: "questionCategoryList",
        components: {
          main: () => importPage("training/questionCategoryList"),
        },
        meta: {
          title: "常见问题分类管理",
          primaryMenuId: "training",
          secondaryMenuId: "questionList",
          tertiaryMenuId: "questionCategoryList",
        },
      },
      {
        path: "questionCategoryEdit",
        components: {
          main: () => importPage("training/questionCategoryEdit"),
        },
        meta: {
          title: "常见问题分类管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "questionList",
          tertiaryMenuId: "questionCategoryList",
        },
      },
      {
        path: "questionList",
        components: {
          main: () => importPage("training/questionList"),
        },
        meta: {
          title: "常见问题管理",
          primaryMenuId: "training",
          secondaryMenuId: "questionList",
          tertiaryMenuId: "questionList",
        },
      },
      {
        path: "questionEdit",
        components: {
          main: () => importPage("training/questionEdit"),
        },
        meta: {
          title: "常见问题管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "questionList",
          tertiaryMenuId: "questionList",
        },
      },
      {
        path: "onlineFQA",
        components: {
          main: () => importPage("training/onlineFQA"),
        },
        meta: {
          title: "在线答疑-未回复",
          primaryMenuId: "training",
          secondaryMenuId: "onlineFQA",
          tertiaryMenuId: "onlineFQA",
        },
      },
      {
        path: "onlineFQAed",
        components: {
          main: () => importPage("training/onlineFQAed"),
        },
        meta: {
          title: "在线答疑-已回复",
          primaryMenuId: "training",
          secondaryMenuId: "onlineFQA",
          tertiaryMenuId: "onlineFQAed",
        },
      },
      {
        path: "onlineFQAEdit",
        components: {
          main: () => importPage("training/onlineFQAEdit"),
        },
        meta: {
          title: "在线答疑-查看",
          primaryMenuId: "training",
          secondaryMenuId: "onlineFQA",
          tertiaryMenuId: "onlineFQA",
        },
      },
      {
        path: "onlineFQAView",
        components: {
          main: () => importPage("training/onlineFQAView"),
        },
        meta: {
          title: "在线答疑-查看",
          primaryMenuId: "training",
          secondaryMenuId: "onlineFQA",
          tertiaryMenuId: "onlineFQAed",
        },
      },
      {
        path: "BaoKaoZiXun",
        components: {
          main: () => importPage("training/BaoKaoZiXun"),
        },
        meta: {
          title: "咨询报考费用",
          primaryMenuId: "training",
          secondaryMenuId: "onlineFQA",
          tertiaryMenuId: "BaoKaoZiXun",
        },
      },
      {
        path: "KaoShiXinXi",
        components: {
          main: () => importPage("training/KaoShiXinXi"),
        },
        meta: {
          title: "咨询考试信息",
          primaryMenuId: "training",
          secondaryMenuId: "onlineFQA",
          tertiaryMenuId: "KaoShiXinXi",
        },
      },
      {
        path: "questionBankList",
        components: {
          main: () => importPage("training/questionBankList"),
        },
        meta: {
          title: "题目管理",
          primaryMenuId: "training",
          secondaryMenuId: "questionBankList",
          tertiaryMenuId: "questionBankList",
        },
      },
      {
        path: "questionBankEdit",
        components: {
          main: () => importPage("training/questionBankEdit"),
        },
        meta: {
          title: "题目管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "questionBankList",
          tertiaryMenuId: "questionBankList",
        },
      },
      {
        path: "feedbackList",
        components: {
          main: () => importPage("training/feedbackList"),
        },
        meta: {
          title: "纠错反馈",
          primaryMenuId: "training",
          secondaryMenuId: "feedbackList",
          tertiaryMenuId: "feedbackList",
        },
      },
      {
        path: "feedbackEdit",
        components: {
          main: () => importPage("training/feedbackEdit"),
        },
        meta: {
          title: "纠错反馈-查看",
          primaryMenuId: "training",
          secondaryMenuId: "feedbackList",
          tertiaryMenuId: "feedbackList",
        },
      },
      {
        path: "questionBankCategoryList",
        components: {
          main: () => importPage("training/questionBankCategoryList"),
        },
        meta: {
          title: "题目分类管理",
          primaryMenuId: "training",
          secondaryMenuId: "questionBankList",
          tertiaryMenuId: "questionBankCategoryList",
        },
      },
      {
        path: "questionBankCategoryEdit",
        components: {
          main: () => importPage("training/questionBankCategoryEdit"),
        },
        meta: {
          title: "题目分类管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "questionBankList",
          tertiaryMenuId: "questionBankCategoryList",
        },
      },
      {
        path: "questionlibraryList",
        components: {
          main: () => importPage("training/questionlibraryList"),
        },
        meta: {
          title: "题库管理",
          primaryMenuId: "training",
          secondaryMenuId: "questionBankList",
          tertiaryMenuId: "questionlibraryList",
        },
      },
      {
        path: "questionlibraryEdit",
        components: {
          main: () => importPage("training/questionlibraryEdit"),
        },
        meta: {
          title: "题库管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "questionBankList",
          tertiaryMenuId: "questionlibraryList",
        },
      },
      {
        path: "zhenTiCateList",
        components: {
          main: () => importPage("training/zhenTiCateList"),
        },
        meta: {
          title: "真题分类管理",
          primaryMenuId: "training",
          secondaryMenuId: "questionBankList",
          tertiaryMenuId: "zhenTiCateList",
        },
      },
      {
        path: "zhenTiCateEdit",
        components: {
          main: () => importPage("training/zhenTiCateEdit"),
        },
        meta: {
          title: "真题分类管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "questionBankList",
          tertiaryMenuId: "zhenTiCateList",
        },
      },
      {
        path: "courseCatalogTKList",
        components: {
          main: () => importPage("training/courseCatalogTKList"),
        },
        meta: {
          title: "题库目录管理",
          primaryMenuId: "training",
          secondaryMenuId: "questionBankList",
          tertiaryMenuId: "courseCatalogTKList",
        },
      },
      {
        path: "courseCatalogTKEdit",
        components: {
          main: () => importPage("training/courseCatalogTKEdit"),
        },
        meta: {
          title: "题库目录管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "questionBankList",
          tertiaryMenuId: "courseCatalogTKList",
        },
      },
      {
        path: "coursesectionsTKList",
        components: {
          main: () => importPage("training/coursesectionsTKList"),
        },
        meta: {
          title: "题库章节管理",
          primaryMenuId: "training",
          secondaryMenuId: "questionBankList",
          tertiaryMenuId: "coursesectionsTKList",
        },
      },
      {
        path: "coursesectionsTKEdit",
        components: {
          main: () => importPage("training/coursesectionsTKEdit"),
        },
        meta: {
          title: "题库章节管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "questionBankList",
          tertiaryMenuId: "coursesectionsTKList",
        },
      },
      {
        path: "testpaperRuleList",
        components: {
          main: () => importPage("training/testpaperRuleList"),
        },
        meta: {
          title: "试卷规则管理",
          primaryMenuId: "training",
          secondaryMenuId: "testpaperList",
          tertiaryMenuId: "testpaperRuleList",
        },
      },
      {
        path: "testpaperRuleEdit",
        components: {
          main: () => importPage("training/testpaperRuleEdit"),
        },
        meta: {
          title: "试卷规则管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "testpaperList",
          tertiaryMenuId: "testpaperRuleList",
        },
      },
      {
        path: "testpaperList",
        components: {
          main: () => importPage("training/testpaperList"),
        },
        meta: {
          title: "试卷管理",
          primaryMenuId: "training",
          secondaryMenuId: "testpaperList",
          tertiaryMenuId: "testpaperList",
        },
      },
      {
        path: "testpaperEdit",
        components: {
          main: () => importPage("training/testpaperEdit"),
        },
        meta: {
          title: "试卷管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "testpaperList",
          tertiaryMenuId: "testpaperList",
        },
      },
      {
        path: "examinationList",
        components: {
          main: () => importPage("training/examinationList"),
        },
        meta: {
          title: "考试管理",
          primaryMenuId: "training",
          secondaryMenuId: "examinationList",
          tertiaryMenuId: "examinationList",
        },
      },
      {
        path: "examinationEdit",
        components: {
          main: () => importPage("training/examinationEdit"),
        },
        meta: {
          title: "考试管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "examinationList",
          tertiaryMenuId: "examinationList",
        },
      },
      {
        path: "examinationCategoryList",
        components: {
          main: () => importPage("training/examinationCategoryList"),
        },
        meta: {
          title: "考试分类管理",
          primaryMenuId: "training",
          secondaryMenuId: "examinationList",
          tertiaryMenuId: "examinationCategoryList",
        },
      },
      {
        path: "examinationCategoryEdit",
        components: {
          main: () => importPage("training/examinationCategoryEdit"),
        },
        meta: {
          title: "考试分类管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "examinationList",
          tertiaryMenuId: "examinationCategoryList",
        },
      },

      {
        path: "coursecategoryList",
        components: {
          main: () => importPage("training/coursecategoryList"),
        },
        meta: {
          title: "课程分类管理",
          primaryMenuId: "training",
          secondaryMenuId: "curriculumList",
          tertiaryMenuId: "coursecategoryList",
        },
      },
      {
        path: "coursecategoryEdit",
        components: {
          main: () => importPage("training/coursecategoryEdit"),
        },
        meta: {
          title: "课程分类管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "curriculumList",
          tertiaryMenuId: "coursecategoryList",
        },
      },
      {
        path: "courseList",
        components: {
          main: () => importPage("training/courseList"),
        },
        meta: {
          title: "课程管理",
          primaryMenuId: "training",
          secondaryMenuId: "courseList",
          tertiaryMenuId: "courseList",
        },
      },
      {
        path: "courseEdit",
        components: {
          main: () => importPage("training/courseEdit"),
        },
        meta: {
          title: "课程管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "courseList",
          tertiaryMenuId: "courseList",
        },
      },
      {
        path: "historicalquestionsList",
        components: {
          main: () => importPage("training/historicalquestionsList"),
        },
        meta: {
          title: "历年真题管理",
          primaryMenuId: "training",
          secondaryMenuId: "courseList",
          tertiaryMenuId: "historicalquestionsList",
        },
      },
      {
        path: "historicalquestionsEdit",
        components: {
          main: () => importPage("training/historicalquestionsEdit"),
        },
        meta: {
          title: "历年真题管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "courseList",
          tertiaryMenuId: "historicalquestionsList",
        },
      },
      {
        path: "coursewareList",
        components: {
          main: () => importPage("training/coursewareList"),
        },
        meta: {
          title: "课件管理",
          primaryMenuId: "training",
          secondaryMenuId: "coursewareList",
          tertiaryMenuId: "coursewareList",
        },
      },
      {
        path: "coursewareEdit",
        components: {
          main: () => importPage("training/coursewareEdit"),
        },
        meta: {
          title: "课件管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "coursewareList",
          tertiaryMenuId: "coursewareList",
        },
      },
      {
        path: "videohandoutList",
        components: {
          main: () => importPage("training/videohandoutList"),
        },
        meta: {
          title: "课程讲义管理",
          primaryMenuId: "training",
          secondaryMenuId: "curriculumList",
          tertiaryMenuId: "videohandoutList",
        },
      },
      {
        path: "videohandoutEdit",
        components: {
          main: () => importPage("training/videohandoutEdit"),
        },
        meta: {
          title: "课程讲义管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "curriculumList",
          tertiaryMenuId: "videohandoutList",
        },
      },
      {
        path: "courseCatalogList",
        components: {
          main: () => importPage("training/courseCatalogList"),
        },
        meta: {
          title: "课程目录管理",
          primaryMenuId: "training",
          secondaryMenuId: "courseList",
          tertiaryMenuId: "courseList",
        },
      },
      {
        path: "courseCatalogEdit",
        components: {
          main: () => importPage("training/courseCatalogEdit"),
        },
        meta: {
          title: "课程目录管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "courseList",
          tertiaryMenuId: "courseList",
        },
      },
      {
        path: "coursesectionsList",
        components: {
          main: () => importPage("training/coursesectionsList"),
        },
        meta: {
          title: "课程章节管理",
          primaryMenuId: "training",
          secondaryMenuId: "courseList",
          tertiaryMenuId: "courseList",
        },
      },
      {
        path: "coursesectionsEdit",
        components: {
          main: () => importPage("training/coursesectionsEdit"),
        },
        meta: {
          title: "课程章节管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "courseList",
          tertiaryMenuId: "courseList",
        },
      },
      {
        path: "simulationList",
        components: {
          main: () => importPage("training/simulationList"),
        },
        meta: {
          title: "模拟练习管理",
          primaryMenuId: "training",
          secondaryMenuId: "simulationList",
          tertiaryMenuId: "simulationList",
        },
      },
      {
        path: "simulationEdit",
        components: {
          main: () => importPage("training/simulationEdit"),
        },
        meta: {
          title: "模拟练习管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "simulationList",
          tertiaryMenuId: "simulationList",
        },
      },
      {
        path: "ztest",
        components: {
          main: () => importPage("training/ztest"),
        },
        meta: {
          title: "模拟练习管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "ztest",
          tertiaryMenuId: "ztest",
        },
      },
      {
        path: "orderList",
        components: {
          main: () => importPage("training/orderList"),
        },
        meta: {
          title: "订单管理",
          primaryMenuId: "training",
          secondaryMenuId: "orderList",
          tertiaryMenuId: "orderList",
        },
      },
      {
        path: "orderEdit",
        components: {
          main: () => importPage("training/orderEdit"),
        },
        meta: {
          title: "订单管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "orderList",
          tertiaryMenuId: "orderList",
        },
      },
      {
        path: "orderAudit",
        components: {
          main: () => importPage("training/orderAudit"),
        },
        meta: {
          title: "订单管理-审核",
          primaryMenuId: "training",
          secondaryMenuId: "orderList",
          tertiaryMenuId: "orderList",
        },
      },
      {
        path: "subOrderList",
        components: {
          main: () => importPage("training/subOrderList"),
        },
        meta: {
          title: "子订单管理",
          primaryMenuId: "training",
          secondaryMenuId: "orderList",
          tertiaryMenuId: "orderList",
        },
      },
      {
        path: "subOrderEdit",
        components: {
          main: () => importPage("training/subOrderEdit"),
        },
        meta: {
          title: "子订单管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "orderList",
          tertiaryMenuId: "orderList",
        },
      },
      {
        path: "ordershList",
        components: {
          main: () => importPage("training/ordershList"),
        },
        meta: {
          title: "售后订单管理",
          primaryMenuId: "training",
          secondaryMenuId: "orderList",
          tertiaryMenuId: "ordershList",
        },
      },
      {
        path: "ordershEdit",
        components: {
          main: () => importPage("training/ordershEdit"),
        },
        meta: {
          title: "售后订单管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "orderList",
          tertiaryMenuId: "ordershList",
        },
      },
      {
        path: "ordershChuli",
        components: {
          main: () => importPage("training/ordershChuli"),
        },
        meta: {
          title: "售后订单处理",
          primaryMenuId: "training",
          secondaryMenuId: "orderList",
          tertiaryMenuId: "ordershList",
        },
      },
      {
        path: "invoiceList",
        components: {
          main: () => importPage("training/invoiceList"),
        },
        meta: {
          title: "发票管理",
          primaryMenuId: "training",
          secondaryMenuId: "orderList",
          tertiaryMenuId: "invoiceList",
        },
      },
      {
        path: "invoiceEdit",
        components: {
          main: () => importPage("training/invoiceEdit"),
        },
        meta: {
          title: "发票管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "orderList",
          tertiaryMenuId: "invoiceList",
        },
      },
      {
        path: "invoiceKaipiao",
        components: {
          main: () => importPage("training/invoiceKaipiao"),
        },
        meta: {
          title: "发票管理-开票",
          primaryMenuId: "training",
          secondaryMenuId: "orderList",
          tertiaryMenuId: "invoiceList",
        },
      },
      {
        path: "invoiceTuipiao",
        components: {
          main: () => importPage("training/invoiceTuipiao"),
        },
        meta: {
          title: "发票管理-退票",
          primaryMenuId: "training",
          secondaryMenuId: "orderList",
          tertiaryMenuId: "invoiceList",
        },
      },
      {
        path: "orderhuanxyList",
        components: {
          main: () => importPage("training/orderhuanxyList"),
        },
        meta: {
          title: "更换学员管理",
          primaryMenuId: "training",
          secondaryMenuId: "orderList",
          tertiaryMenuId: "orderhuanxyList",
        },
      },
      {
        path: "orderhuanxyEdit",
        components: {
          main: () => importPage("training/orderhuanxyEdit"),
        },
        meta: {
          title: "更换学员管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "orderList",
          tertiaryMenuId: "orderhuanxyList",
        },
      },
      {
        path: "orderhuanxyShenhe",
        components: {
          main: () => importPage("training/orderhuanxyShenhe"),
        },
        meta: {
          title: "更换学员审核",
          primaryMenuId: "training",
          secondaryMenuId: "orderList",
          tertiaryMenuId: "orderhuanxyList",
        },
      },
      {
        path: "ordersqcxList",
        components: {
          main: () => importPage("training/ordersqcxList"),
        },
        meta: {
          title: "申请重学审核",
          primaryMenuId: "training",
          secondaryMenuId: "orderList",
          tertiaryMenuId: "ordersqcxList",
        },
      },
      {
        path: "ordersqcxShenhe",
        components: {
          main: () => importPage("training/ordersqcxShenhe"),
        },
        meta: {
          title: "申请重学审核",
          primaryMenuId: "training",
          secondaryMenuId: "orderList",
          tertiaryMenuId: "ordersqcxList",
        },
      },
      {
        path: "ordersqzsList",
        components: {
          main: () => importPage("training/ordersqzsList"),
        },
        meta: {
          title: "申请证书审核",
          primaryMenuId: "training",
          secondaryMenuId: "orderList",
          tertiaryMenuId: "ordersqzsList",
        },
      },
      {
        path: "ordersqzsShenhe",
        components: {
          main: () => importPage("training/ordersqzsShenhe"),
        },
        meta: {
          title: "申请证书审核",
          primaryMenuId: "training",
          secondaryMenuId: "orderList",
          tertiaryMenuId: "ordersqzsList",
        },
      },
      {
        path: "orderset",
        components: {
          main: () => importPage("training/orderset"),
        },
        meta: {
          title: "订单设置",
          primaryMenuId: "training",
          secondaryMenuId: "orderList",
          tertiaryMenuId: "orderset",
        },
      },
      {
        path: "viewingProcess",
        components: {
          main: () => importPage("training/viewingProcess"),
        },
        meta: {
          title: "看课流程",
          primaryMenuId: "training",
          secondaryMenuId: "exambookList",
          tertiaryMenuId: "viewingProcess",
        },
      },
      {
        path: "unitinfoList",
        components: {
          main: () => importPage("training/unitinfoList"),
        },
        meta: {
          title: "单位管理",
          primaryMenuId: "training",
          secondaryMenuId: "unitinfoList",
          tertiaryMenuId: "unitinfoList",
        },
      },
      {
        path: "unitinfoEdit",
        components: {
          main: () => importPage("training/unitinfoEdit"),
        },
        meta: {
          title: "单位管理-编辑",
          primaryMenuId: "training",
          secondaryMenuId: "unitinfoList",
          tertiaryMenuId: "unitinfoList",
        },
      },
      {
        path: "usedList",
        components: {
          main: () => importPage("training/usedList"),
        },
        meta: {
          title: "已使用的学员",
          primaryMenuId: "training",
          secondaryMenuId: "unitinfoList",
          tertiaryMenuId: "unitinfoList",
        },
      },
      {
        path: "dealerorderList",
        components: {
          main: () => importPage("training/dealerorderList"),
        },
        meta: {
          title: "经销商订单管理",
          primaryMenuId: "training",
          secondaryMenuId: "dealerorderList",
          tertiaryMenuId: "dealerorderList",
        },
      },
      {
        path: "dealerorderEdit",
        components: {
          main: () => importPage("training/dealerorderEdit"),
        },
        meta: {
          title: "经销商订单管理-查看",
          primaryMenuId: "training",
          secondaryMenuId: "dealerorderList",
          tertiaryMenuId: "dealerorderList",
        },
      },
      {
        path: "dealersubOrderList",
        components: {
          main: () => importPage("training/dealersubOrderList"),
        },
        meta: {
          title: "子订单信息",
          primaryMenuId: "training",
          secondaryMenuId: "dealerorderList",
          tertiaryMenuId: "dealerorderList",
        },
      },
      {
        path: "exportQRCode",
        components: {
          main: () => importPage("training/exportQRCode"),
        },
        meta: {
          title: "导出二维码",
          primaryMenuId: "training",
          secondaryMenuId: "exambookList",
          tertiaryMenuId: "courseQrCodeList",
        },
      },
      {
        path: "courseCheckIn",
        components: {
          main: () => importPage("training/courseCheckIn"),
        },
        meta: {
          title: "签到信息",
          primaryMenuId: "Industry",
          secondaryMenuId: "Course",
          tertiaryMenuId: "courseCheckIn",
          keepAlive: false,
        },
      },
      {
        path: "courseViewLog",
        components: {
          main: () => importPage("training/courseViewLog"),
        },
        meta: {
          title: "观看记录",
          primaryMenuId: "Industry",
          secondaryMenuId: "Course",
          tertiaryMenuId: "courseViewLog",
          keepAlive: false,
        },
      },
      {
        path: "courseTimeSpanList",
        components: {
          main: () => importPage("training/courseTimeSpanList"),
        },
        meta: {
          title: "直播时段管理",
          primaryMenuId: "Industry",
          secondaryMenuId: "Course",
          tertiaryMenuId: "courseTimeSpanList",
          keepAlive: false,
        },
      },
      {
        path: "courseTimeSpanEdit",
        components: {
          main: () => importPage("training/courseTimeSpanEdit"),
        },
        meta: {
          title: "直播时段管理-编辑",
          primaryMenuId: "Industry",
          secondaryMenuId: "Course",
          tertiaryMenuId: "courseTimeSpanList",
          keepAlive: false,
        },
      },
      {
        path: "roomList",
        components: {
          main: () => importPage("training/roomList"),
        },
        meta: {
          title: "直播间列表",
          primaryMenuId: "Industry",
          secondaryMenuId: "Course",
          tertiaryMenuId: "courseList",
          keepAlive: false,
        },
      },
      {
        path: "roomInfo",
        components: {
          main: () => importPage("training/roomInfo"),
        },
        meta: {
          title: "直播间地址",
          primaryMenuId: "Industry",
          secondaryMenuId: "Course",
          tertiaryMenuId: "courseList",
          keepAlive: false,
        },
      },
      {
        path: "rollcall",
        components: {
          main: () => importPage("training/rollcall"),
        },
        meta: {
          title: "签到信息",
          primaryMenuId: "Industry",
          secondaryMenuId: "Course",
          tertiaryMenuId: "courseList",
          keepAlive: false,
        },
      },
      {
        path: "rollcallInfo",
        components: {
          main: () => importPage("training/rollcallInfo"),
        },
        meta: {
          title: "签到用户信息",
          primaryMenuId: "Industry",
          secondaryMenuId: "Course",
          tertiaryMenuId: "courseList",
          keepAlive: false,
        },
      },



    ],
  },

  
  IndustryTrainingRoutes,
  webSiteRoutes
];

const router = createRouter({
  mode: "hash",
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
